
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import React from 'react'
import { useTranslation } from '../services/translation'
import CardProject from '../components/CardProject'
import { Col, Container, Row } from 'react-bootstrap'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import Layout from '../components/Layout'
import BannerHome from '../components/BannerHome'
import { initializeApollo } from '../services/apolloClient'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import Link from 'next/link'
import { useLocales } from '../contexts/locales'
import { LinkText } from '../utils/linkText'
import GoogleAd from '../components/GoogleAd'

const modelQueryFields = `
  models {
    id,
    slug,
    name,
    deadline,
    description,
    status,
    modelingStatus,
    sensitiveContent,
    featuredPhoto {
      photo
    },
    partner,
    likes {
      id,
      name
    },
    backers {
      quotasQtd
    },
    modeler {
      name,
      slug,
      id
    },
    totalQuotas,
    nextStep,
    quotaPrice,
    quotaPriceWithFee,
    proposals {
      modeler {
        id
      }
    },
    organizer {
      id
    },
    proposalType
  },
  count,
  categories {
    count,
    id,
    name {
      name,
      language
    }
  }
`

const GET_DATA = gql`
  query homeData {
    charactersModels: getModels(status: [FINISHED, RAISING_FUNDS, MODELING], limit: 10, orderBy: updatedAt_DESC, excludeFree: true, excludeSensitive: true, categories:["5e3b39aaa6dbbdfc8fc28f19"]) {
      ${modelQueryFields}
    }
    cosplayModels: getModels(status: [FINISHED, RAISING_FUNDS, MODELING], limit: 10, orderBy: updatedAt_DESC, excludeFree: true, excludeSensitive: true, categories:["5e3b45f5a6dbbdfc8fc46f1d"]) {
      ${modelQueryFields}
    }
    decorationModels: getModels(status: [FINISHED, RAISING_FUNDS, MODELING], limit: 10, orderBy: updatedAt_DESC, excludeFree: true, excludeSensitive: true, categories:["5e3b48eaa6dbbdfc8fc4e5b0"]) {
      ${modelQueryFields}
    }
    latesRequestedtModels: getModels(status: [REQUESTED], limit: 8, orderBy: updatedAt_DESC, excludeSensitive: true) {
      ${modelQueryFields}
    }
  }
`

function IndexPage() {
  const { t } = useTranslation()
  const { currency } = useLocales()

  const { data, loading, refetch } = useQuery(GET_DATA, {
    notifyOnNetworkStatusChange: true,
  })

  const onUpdateModel = () => {
    refetch()
  }
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }
  const itemCarousel = (mddata, url) => {
    let html = mddata.map(model => (
      <CardProject key={model.id} model={model} onUpdateModel={onUpdateModel} />
    ))

    if (mddata.length >= 8) {
      html.push(
        <div className='div-vertodos' key={11}>
          <Link href={url}>
            <a className='bt-vertodos'>
              <i className='fa fa-search-plus'></i>
              <p>{t('home.btnvertodos')}</p>
            </a>
          </Link>
        </div>
      )
    }
    return html
  }

  return (
    <Layout>
      <BannerHome />
      {/* <Carousel responsive={responsive} itemClass='p-3'>
        <div>GAMES</div>
        <div>FILMES</div>
        <div>ANIMES</div>
        <div>GAMES</div>
        <div>FILMES</div>
        <div>ANIMES</div>
        <div>GAMES</div>
        <div>FILMES</div>
        <div>ANIMES</div>
      </Carousel> */}
      <div className='mb-4'>
        <GoogleAd />
      </div>
      <Container fluid>
        {!loading && data.charactersModels.models.length && (
          <>
            <h2>{t('home.tit1')}</h2>
            <div className='gridHolder'>
              {data.charactersModels.models.map(model => (
                <CardProject
                  key={model.id}
                  model={model}
                  onUpdateModel={onUpdateModel}
                />
              ))}
            </div>

            {data.charactersModels.models.length >= 10 && (
              <div className='div-vertodos' key={1}>
                <Link href='/allmodels/categories-5e3b39aaa6dbbdfc8fc28f19_Characters'>
                  <a className='bt-vertodos'>
                    <i className='fa fa-search-plus'></i>
                    <p>{t('home.btnvertodos')}</p>
                  </a>
                </Link>
              </div>
            )}
          </>
        )}
      </Container>
      <GoogleAd block={2} />
      <div className='bg-amarelo py-5 my-4'>
        <Container>
          <h2 className='text-white mb-4 fs-1'>{t('home.tit2')}</h2>
          <Row>
            <Col xs={12} sm={12} md={5} lg={5} className='font-oxygen'>
              <h3 className='fw-bold text-cor-principal'>
                {t('home.txtmodeler1')}
              </h3>
              <h4 className='text-cor-anlga-esc'>{t('home.txtmodeler2')}</h4>
              <LinkText
                className='btn-paralel mt-5 ms-3 btn-full'
                href={'addrequest'}
              >
                <span className='skew-fix'>
                  {t('botoes.encontreModelador')}
                </span>
              </LinkText>
            </Col>
            <Col xs={12} sm={12} md={7} lg={7}>
              <Carousel responsive={responsive} itemClass='p-3'>
                {itemCarousel(
                  data.latesRequestedtModels.models,
                  '/modelsfindmodeler'
                )}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='mb-4'>
        <GoogleAd block={2} />
      </div>
      <Container fluid>
        {!loading && data.cosplayModels.models.length && (
          <>
            <h2>{t('home.tit3')}</h2>
            <div className='gridHolder'>
              {data.cosplayModels.models.map(model => (
                <CardProject
                  key={model.id}
                  model={model}
                  onUpdateModel={onUpdateModel}
                />
              ))}
            </div>

            {data.cosplayModels.models.length >= 10 && (
              <div className='div-vertodos' key={1}>
                <Link href='/allmodels/categories-5e3b45f5a6dbbdfc8fc46f1d_Cosplay'>
                  <a className='bt-vertodos'>
                    <i className='fa fa-search-plus'></i>
                    <p>{t('home.btnvertodos')}</p>
                  </a>
                </Link>
              </div>
            )}
          </>
        )}
      </Container>
      <GoogleAd block={2} />
      <div className='bg-verde-azul pt-5 pb-4 my-4'>
        <Container>
          <h2 className='text-center text-white mb-4 fs-1'>{t('home.tit4')}</h2>

          <Row className='font-oxygen text-white'>
            <Col xs={12} sm={12} md={6} lg={6} className='my-3'>
              <div className='text-center'>
                <i className='icone-desconto' />
              </div>
              <p className='fs-3'>{t('home.txtvaquinha1')}</p>
              <LinkText
                className='btn-paralel bt-amarelo mx-auto btn-full'
                href={'howworks'}
              >
                <span className='skew-fix'>{t('botoes.comofunciona')}</span>
              </LinkText>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} className='my-3'>
              <div className='text-center'>
                <i className='icone-campanha' />
              </div>{' '}
              <p className='fs-3'>{t('home.txtvaquinha2')}</p>
              <LinkText
                className='btn-paralel bt-amarelo mx-auto btn-full'
                href={'modelsincampaign'}
              >
                <span className='skew-fix'>{t('botoes.campanhas')}</span>
              </LinkText>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='mb-4'>
        <GoogleAd block={2} />
      </div>
      <Container fluid>
        {!loading && data.decorationModels.models.length && (
          <>
            <h2>{t('home.tit5')}</h2>
            <div className='gridHolder'>
              {data.decorationModels.models.map(model => (
                <CardProject
                  key={model.id}
                  model={model}
                  onUpdateModel={onUpdateModel}
                />
              ))}
            </div>

            {data.decorationModels.models.length >= 10 && (
              <div className='div-vertodos' key={1}>
                <Link href='/allmodels/categories-5e3b48eaa6dbbdfc8fc4e5b0_Decoration'>
                  <a className='bt-vertodos'>
                    <i className='fa fa-search-plus'></i>
                    <p>{t('home.btnvertodos')}</p>
                  </a>
                </Link>
              </div>
            )}
          </>
        )}
      </Container>

      <div className='py-5 my-4'>
        <Container>
          <h2 className='text-center text-dark mb-4 fs-1'>{t('home.tit6')}</h2>
          <LinkText
            className='btn-paralel bt-amarelo mx-auto btn-full'
            href={'howworks'}
          >
            <span className='skew-fix'>{t('botoes.comofunciona')}</span>
          </LinkText>
        </Container>
      </div>
      {currency !== 'BRL' && (
        <p className='dollar-observation'>{t('dollarObservation')}</p>
      )}
    </Layout>
  )
}

 const _getServerSideProps = async () => {
  const apolloClient = initializeApollo()

  await apolloClient.query({
    query: GET_DATA,
  })

  return {
    props: {
      initialApolloState: apolloClient.cache.extract(),
    },
  }
}

export default IndexPage


    export async function getServerSideProps(ctx) {
        let res = _getServerSideProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/index',
              loaderName: 'getServerSideProps',
              ...__i18nConfig,
              
            }))
          }
        }
    }
  